import React, { useContext, useMemo } from "react";
import { AuthContext, IntlContext, FlashContext } from "context";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import dataFetcher from "dataProvider/Profile";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Button from "ui/Button";
import ProfileFormFields from "./FormFields";
import { Role } from "dataProvider/User";
import blobToBase64 from "helpers/file/blobToBase64";

interface Props {
    onEditSubmitSuccess: () => void;
}

const ProfileCommonEdit: React.FC<Props> = ({ onEditSubmitSuccess }) => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const flashMessage = useContext(FlashContext);

    const onSubmit = async (values: any) => {
        try {
            if (
                values?.resources.provider &&
                values?.resources.provider.kbis?.content &&
                values.resources.provider.kbis.content instanceof Blob
            ) {
                values.resources.provider.kbis.content = (await blobToBase64(
                    values.resources.provider.kbis.content
                )) as Blob;
            }

            await dataFetcher.update(account!.user.id, values);
            flashMessage?.addSuccess(intl.formatMessage("profile.user.data.update"));
            onEditSubmitSuccess();
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    const initialValues = useMemo(() => {
        const data = {
            gender: account?.user.gender,
            last_name: account?.user.last_name,
            first_name: account?.user.first_name,
            phone: account?.user.phone,
            address: account?.user.address,
            email: account?.user.email,
            is_attach_company:
                account?.user.currentRole === Role.CLIENT && account?.resources?.client[0]?.company_id ? "1" : "0",
            resources: {},
        };

        if (account?.user.currentRole === Role.CLIENT) {
            data.resources = {
                client: {
                    company_id: account?.resources?.client[0]?.company_id,
                    company_name: account?.resources?.client[0].company_name,
                    code: account?.resources?.client[0].company_code,
                    has_pet: account?.resources?.client[0].has_pet ? "1" : "0",
                    services: account?.resources?.client[0].services,
                    comment: account?.resources?.client[0].comment,
                    birthday: account?.resources?.client[0].birthday,
                    code_cesu: account?.resources?.client[0].code_cesu,
                    num_cesu: account?.resources?.client[0].num_cesu,
                    initial_num_cesu: account?.resources?.client[0].num_cesu,
                    account_name: account?.resources?.client[0].account_name,
                    iban: account?.resources?.client[0].iban,
                    bic: account?.resources?.client[0].bic,
                },
            };
        }

        if (account?.user.currentRole === Role.PROVIDER) {
            data.resources = {
                provider: {
                    has_job: account?.resources?.provider[0].has_job ? "1" : "0",
                    kbis: account?.resources?.provider[0].kbis_id,
                    account_name: account?.resources?.provider[0].account_name,
                    iban: account?.resources?.provider[0].iban,
                    bic: account?.resources?.provider[0].bic,
                    type: account?.resources?.provider[0].type,
                    siret: account?.resources?.provider[0].siret,
                    has_tva: account?.resources?.provider[0].has_tva === "1" ? "1" : "0",
                    tva_number: account?.resources?.provider[0].tva_number,
                    tva_rate: account?.resources?.provider[0].tva_rate ?? null,
                    birthday: account?.resources?.provider[0].birthday,
                },
            };
        }

        return data;
    }, [account]);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="register__form">
                    <ProfileFormFields />
                    <div className="text-center margin-top--3 padding-top--3">
                        <Button className="btn btn__outline--primary" type="submit" disabled={submitting}>
                            {intl.formatMessage("generic.action.save")}
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default ProfileCommonEdit;
