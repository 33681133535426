import fetch, { parseListResponse, parseResponse } from "./fetch";

export interface CesuNumInterface {
    id: string;
}

export enum CodeCesu {
    ADD_CESU = "ADD_CESU",
    HAS_CESU = "HAS_CESU",
    WITHOUT_CESU = "WITHOUT_CESU",
}

class Cesu {}

const dataFetcher = new Cesu();

export default dataFetcher;
