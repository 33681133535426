import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { AuthContext, IntlContext } from "context";
import Image from "components/File/Image";
import Button from "ui/Button";
import NotificationTopbarIcon from "app/Notification/TopbarIcon";
import { ThreadConversationInterface } from "dataProvider/Thread";
import { NotificationContext } from "app/Notification/Context";

const Topbar: React.FC = () => {
    const { isLoggedIn, logout, account, currentRole, selectRole } = useContext(AuthContext);
    const intl = useContext(IntlContext);
    const { threads } = useContext(NotificationContext);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    let messageAtRead = 0;
    if (threads !== undefined) {
        const listConversationRead = threads.map((value: ThreadConversationInterface) =>
            account?.user.currentRole === "provider" ? value.show_message_provider : value.show_message_client
        );
        messageAtRead = listConversationRead.reduce(
            (acumulateur: number, current: boolean) => acumulateur + (!current ? 1 : 0),
            0
        );
    }

    return (
        <header className={`header${openMenu ? " header--open" : ""}`}>
            <div className="container">
                <Link className="header__logo" to="/">
                    <img src="/images/logo/logo-twoghether.svg" alt="Logo Twoghether" />
                    <span className="visuallyhidden">{intl.formatMessage("menu.home")}</span>
                </Link>
                <div className="header__menus">
                    <ul className={"header__menu" + (isLoggedIn ? " center" : "")}>
                        <li>
                            <NavLink to="/">{intl.formatMessage("menu.welcome")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/nos-services">{intl.formatMessage("menu.our_services")}</NavLink>
                        </li>
                        <li>
                            <a href={intl.formatMessage("menu.news.link." + process.env.REACT_APP_ENV)}>
                                {intl.formatMessage("menu.news")}
                            </a>
                        </li>
                        <li>
                            <a href={intl.formatMessage("menu.about.link." + process.env.REACT_APP_ENV)}>
                                {intl.formatMessage("menu.about")}
                            </a>
                        </li>
                        <li>
                            <NavLink to="/contact">{intl.formatMessage("menu.contact")}</NavLink>
                        </li>
                    </ul>
                    {!isLoggedIn ? (
                        <ul className="header__right">
                            <li>
                                <Link className="btn btn--transparent" to="/inscription">
                                    {intl.formatMessage("menu.register")}
                                </Link>
                            </li>
                            <li>
                                <Link className="btn btn--primary" to="/connexion">
                                    {intl.formatMessage("menu.login")}
                                </Link>
                            </li>
                        </ul>
                    ) : (
                        <ul className="header__right">
                            <li className="header__profil-item margin-left--0">
                                <Link to="/messagerie" className="btn__icon">
                                    <span className="icon icon-message"></span>
                                    {messageAtRead !== 0 && <span className="notification-badge">{messageAtRead}</span>}
                                </Link>
                            </li>
                            <NotificationTopbarIcon />
                            <li className="header__profil-item header__profil-item--profil margin-left--0">
                                <Link className="header__btn-profil" to="/mon-compte/">
                                    <span className="header__btn-profil-name">{account?.user.first_name}</span>
                                    <div className="header__btn-profil-img-wrapper">
                                        {account?.user.picture_id ? (
                                            <Image className="header__btn-profil-img" id={account?.user.picture_id} />
                                        ) : (
                                            <div className="header__default-profile-image">
                                                <span className="header__default-profile-image--initials">
                                                    {account?.user.last_name.charAt(0)}{" "}
                                                    {account?.user.first_name.charAt(0)}{" "}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <span className="visuallyhidden">{intl.formatMessage("menu.profile")}</span>
                                </Link>

                                <ul className="header__submenu">
                                    {currentRole === "provider" && (
                                        <React.Fragment>
                                            <li className="header__submenu-item">
                                                <Link className="header__submenu-link" to={"/mon-calendrier"}>
                                                    {intl.formatMessage("menu.calendar")}
                                                </Link>
                                            </li>
                                            <li className="header__submenu-item">
                                                <Link
                                                    className="header__submenu-link"
                                                    to={"/mon-calendrier/mes-reservations"}
                                                >
                                                    {intl.formatMessage("menu.message.provider")}
                                                </Link>
                                            </li>
                                            <li className="header__submenu-item">
                                                <Link className="header__submenu-link" to={"/mes-services"}>
                                                    {intl.formatMessage("menu.services")}
                                                </Link>
                                            </li>
                                            <li className="header__submenu-item">
                                                <Link className="header__submenu-link" to={"/mes-factures"}>
                                                    {intl.formatMessage("menu.invoices")}
                                                </Link>
                                            </li>
                                        </React.Fragment>
                                    )}
                                    {currentRole === "client" && (
                                        <React.Fragment>
                                            <li className="header__submenu-item">
                                                <Link className="header__submenu-link" to={"/mes-reservations"}>
                                                    {intl.formatMessage("menu.booking")}
                                                </Link>
                                            </li>
                                            <li className="header__submenu-item">
                                                <Link className="header__submenu-link" to={"/mes-reservations"}>
                                                    {intl.formatMessage("menu.message.client")}
                                                </Link>
                                            </li>
                                            <li className="header__submenu-item">
                                                <Link className="header__submenu-link" to={"/mes-factures"}>
                                                    {intl.formatMessage("menu.invoices")}
                                                </Link>
                                            </li>
                                        </React.Fragment>
                                    )}
                                    <li className="header__submenu-item">
                                        <Link className="header__submenu-link" to={"/mon-compte/"}>
                                            {intl.formatMessage("generic.menu.my_account")}
                                        </Link>
                                    </li>
                                    {account?.user.roles.includes("provider") &&
                                        account?.user.roles.includes("client") && (
                                            <li className="header__submenu-item">
                                                {currentRole === "client" && (
                                                    <Button
                                                        className="header__submenu-link"
                                                        onClick={() => selectRole("provider")}
                                                    >
                                                        {intl.formatMessage("generic.menu.provider_mode")}
                                                    </Button>
                                                )}
                                                {currentRole === "provider" && (
                                                    <Button
                                                        className="header__submenu-link"
                                                        onClick={() => selectRole("client")}
                                                    >
                                                        {intl.formatMessage("generic.menu.client_mode")}
                                                    </Button>
                                                )}
                                            </li>
                                        )}
                                    <li className="header__submenu-item header__submenu-item--border">
                                        <Button className="header__submenu-link" onClick={logout}>
                                            {intl.formatMessage("authentication.login.action.logout")}
                                        </Button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    )}
                </div>
                <Button onClick={() => setOpenMenu(!openMenu)} className="header__toggle">
                    <Icon icon={{ prefix: "fas", iconName: "bars" }} />
                    <span className="visuallyhidden">{intl.formatMessage("menu.open")}</span>
                </Button>
            </div>
        </header>
    );
};

export default Topbar;
