import React, { PropsWithChildren } from "react";
import fetch from "dataProvider/fetch";
import AuthenticationService, { AuthData } from "dataProvider/AuthenticationService";
import getIn from "helpers/getIn";
import Acl from "./Acl";
import { Category } from "dataProvider/Category";

interface Client {
    id: string;
    company_id?: string;
    company_name: string;
    company_code: string;
    company_percentage: string;
    has_pet: string;
    services: Array<Category>;
    birthday: string;
    comment?: string;
    rating: { average: number; count: number };
    code_cesu?: string;
    num_cesu?: string;
    account_name?: string;
    iban?: string;
    bic?: string;
}

interface Company {
    id: string;
    name: string;
    code: string;
    siren: string;
    limit: string;
    nb_employees: string;
    function: string;
    percentage: string;
    signed_date: string;
}

interface Provider {
    id: string;
    birthday: string;
    has_job: string;
    identity_document_id: string;
    kbis_id: string;
    account_name: string;
    iban: string;
    bic: string;
    type: string;
    siret: string;
    legal_status: string;
    social_capital: string;
    has_tva: string;
    tva_number: string;
    tva_rate: number;
    services: Array<string>;
    travel_range: string;
    description: string;
    rating: { average: number; count: number };
}

interface Resource {
    client: Array<Client>;
    company: Array<Company>;
    provider: Array<Provider>;
}

interface Account {
    user: {
        id: string;
        gender: string;
        first_name: string;
        last_name: string;
        phone: string;
        username: string;
        email: string;
        language_code: string;
        roles: Array<string>;
        address: string;
        house_number: string;
        street: string;
        postcode: string;
        city: string;
        coordinates: {
            lat: Number;
            lon: Number;
        };
        picture_id?: string;
        currentRole?: string;
        status: string;
    };
    acl: Acl;
    resources?: Resource;
}

export interface AuthenticationProviderInterface extends Readonly<{}> {
    isLoading: Boolean;
    isLoggedIn: Boolean;
    account: Account | null;
    currentRole: string | null;
    login: (authInfo: { username: string; password: string }) => Promise<void>;
    logout: () => Promise<void>;
    isAllowed: (capability: string) => boolean;
    filterAllowed: (actions: HashMap<any>, acl: HashMap<string>) => HashMap<any>;
    updateAccount: () => Promise<void>;
    selectRole: (roleId: string) => Promise<void>;
    displaySelectRole: () => boolean | undefined;
}

export const ACCOUNT_KEY: string = "account";

function storeDataToAccount(data: AuthData, resetRole: boolean = false): Account {
    const account = {
        user: getIn(data, "user"),
        acl: new Acl(),
        resources: getIn(data, "resources"),
    };
    if (resetRole) {
        delete account.user.currentRole;
    }

    data.capabilities.forEach((capability) => {
        account.acl.allow(capability);
    });

    return account;
}

function getAccount(): Account | null {
    if (fetch.sessionIsExpired()) {
        return null;
    }
    try {
        const account = localStorage.getItem(ACCOUNT_KEY);
        if (account) {
            return storeDataToAccount(JSON.parse(account));
        }
        return null;
    } catch (err) {
        return null;
    }
}

function isLoggedIn(): boolean {
    return !!getAccount() && !fetch.sessionIsExpired();
}

const AuthContext = React.createContext<AuthenticationProviderInterface>({} as AuthenticationProviderInterface);

class AuthProvider extends React.Component<PropsWithChildren<Readonly<{}>>, AuthenticationProviderInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            isLoading: true,
            isLoggedIn: false,
            account: null,
            currentRole: null,
            login: this.login,
            logout: this.logout,
            isAllowed: this.isAllowed,
            filterAllowed: this.filterAllowed,
            updateAccount: this.updateAccount,
            selectRole: this.selectRole,
            displaySelectRole: this.displaySelectRole,
        };
    }

    componentDidMount = async () => {
        try {
            if (!isLoggedIn()) {
                return;
            }

            try {
                await this.updateAccount();
            } catch (e) {
                this.logout();
            }
        } finally {
            this.setState({ isLoading: false });
        }
    };

    isAllowed = (capability: string) => {
        return !!this.state.account && this.state.account.acl.isAllowed(capability);
    };

    filterAllowed = (actions: HashMap<any>, acl: HashMap<string>) => {
        const actionsAllowed: HashMap<any> = {};
        for (const action in actions) {
            if (!acl[action]) {
                actionsAllowed[action] = actions[action];
            } else if (this.isAllowed(acl[action])) {
                actionsAllowed[action] = actions[action];
            }
        }
        return actionsAllowed;
    };

    login = async ({ username, password }: { username: string; password: string }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const token = await AuthenticationService.csrfToken();
        const data = await AuthenticationService.login(username, password);
        const account = Object.assign({}, data);
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({
            isLoggedIn: true,
            account: storeDataToAccount(account, true),
            currentRole: this.getRoleIfUnique(account),
        });
    };

    logout = async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const bugIE = await AuthenticationService.logout();
        } finally {
            localStorage.removeItem(ACCOUNT_KEY);
            fetch.clearSession();
            this.setState({ isLoggedIn: false, account: null, currentRole: null });
            window.location.href = "/";
        }
    };

    updateAccount = async () => {
        let data: any;

        try {
            data = await AuthenticationService.fresh();
        } catch (e) {
            this.logout();
        }

        if (data !== undefined && data?.error !== undefined) {
            this.logout();
        }

        if (data !== undefined && data?.user !== undefined) {
            const account = Object.assign({}, data);
            localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
            let currentRole = this.state.currentRole == null ? account.user.currentRole : this.state.currentRole;
            this.setState({
                isLoggedIn: true,
                account: storeDataToAccount(account),
                currentRole: currentRole ?? this.getRoleIfUnique(account),
            });
        }
    };

    getRoleIfUnique = (account: Account | AuthData) => {
        return account.user.roles.length === 1 ? account.user.roles[0] : null;
    };

    selectRole = async (roleId: string) => {
        this.setState({ isLoading: true });
        try {
            const data = await AuthenticationService.selectRole(roleId);
            const account: any = Object.assign({}, data);
            this.setState({
                account: storeDataToAccount(account),
                currentRole: roleId,
                isLoading: false,
            });
        } catch (err) {
            // no-op
        }
    };

    displaySelectRole = () => {
        return (
            this.state.isLoggedIn &&
            this.state.account?.user.roles &&
            this.state.account?.user.roles.length !== 1 &&
            !this.state.account?.user.currentRole
        );
    };

    render() {
        return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
    }
}

const Consumer = AuthContext.Consumer;

export { AuthProvider as Provider, Consumer, AuthContext as Context };
